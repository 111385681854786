import MuiTextField, { TextFieldProps as MuiTextFieldProps } from "@material-ui/core/TextField";

export type TextFieldProps = MuiTextFieldProps & {
  readOnly?: boolean;
};

export const TextField = ({ readOnly, InputProps, ...textFieldProps }: TextFieldProps) => {
  if (!InputProps) {
    InputProps = {};
  }
  if (readOnly !== undefined) {
    InputProps = { ...InputProps, readOnly };
  }
  return (
    <MuiTextField
      variant="filled"
      InputLabelProps={{ shrink: true }}
      InputProps={InputProps}
      {...textFieldProps}
    />
  );
};
