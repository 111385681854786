import { PropsWithChildren } from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

export type ButtonProps = PropsWithChildren<{
  mode?: "primary" | "secondary" | "warning";
  size?: "small" | "medium" | "large";
  onClick?: MuiButtonProps["onClick"];
  className?: MuiButtonProps["className"];
  type?: "submit" | "button";
}>;

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
}));

export const Button = (props: ButtonProps) => {
  const classes = useStyles(props);

  return (
    <MuiButton
      className={props.mode === "warning" ? classes.warning : ""}
      size="small"
      variant={(props.mode ?? "primary") === "secondary" ? "outlined" : "contained"}
      color="primary"
      disableElevation
      {...props}
    />
  );
};
