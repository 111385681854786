import { createMuiTheme } from "@material-ui/core/styles";

import "@fontsource/roboto/index.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/500.css";
import "@fontsource/lato/index.css";
import "@fontsource/lato/700.css";

export const colors = {
  white: "#F9F9F9",

  policeBlue: "#143675",

  highlight: "#2587BC",
  warning: "#EF9F16",

  divider: "#D4D4D4",
  background: "#F9F9F9",

  text: "#0E0E0F",
  menuText: "#AAAAAA",
};

const baseTheme = createMuiTheme();

export const muiTheme = createMuiTheme({
  palette: {
    common: {
      white: colors.white,
    },
    primary: {
      main: colors.highlight,
      contrastText: colors.background,
    },
    warning: {
      main: colors.warning,
      contrastText: colors.background,
    },
    divider: colors.divider,
  },
  typography: {
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "2.5rem",
    },
    button: {
      fontWeight: 700,
      letterSpacing: "0.02em",
      textTransform: "none",
    },
  },
  overrides: {
    MuiButton: {
      sizeSmall: {
        borderRadius: 16,
        fontSize: 14,
      },
      root: {
        borderRadius: 10,
        fontSize: 14,
      },
    },
    MuiFilledInput: {
      underline: {
        "&:before, &:hover:before": {
          borderBottom: `2px solid ${colors.divider}`,
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 15,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
        backgroundColor: colors.background,
      },
      input: {
        paddingTop: 12.5,
        paddingBottom: 12.5,
      },
    },
    MuiInputLabel: {
      root: {
        color: colors.highlight,
        fontWeight: "bold",
      },
    },
    MuiSwitch: {
      root: {
        padding: 8,
      },
      track: {
        height: 22,
        opacity: 0.2,
        borderRadius: 7,
        $checked: {
          backgroundColor: colors.background,
        },
      },
      thumb: {
        borderRadius: 7,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 12,
        backgroundColor: "initial",
      },
    },
    MuiDialogContent: {
      root: {
        backgroundColor: colors.background,
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: colors.background,
      },
      spacing: {
        "& > :not(:first-child)": {
          marginLeft: 32,
        },
        "& > :last-child": {
          marginRight: 16,
        },
        "& > :first-child": {
          marginLeft: 16,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: colors.highlight,
        color: colors.background,
        paddingTop: 8,
        paddingBottom: 8,
        textAlign: "center",
      },
    },
    MuiBreadcrumbs: {
      li: {
        fontFamily: "Lato",
        letterSpacing: 0.08,
        fontWeight: 400,
        fontSize: 16,
        marginTop: 8,
        color: colors.background,
      },
      separator: {
        marginTop: 8,
        color: colors.background,
        marginLeft: 16,
        marginRight: 16,
      },
    },
    MuiIconButton: {
      root: {
        color: "inherit",
      },
    },
    MuiTab: {
      root: {
        minWidth: 72,
        [baseTheme.breakpoints.up("sm")]: {
          minWidth: 72,
        },
      },
      fullWidth: {
        minWidth: 72,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: colors.background,
        height: 4,
        borderRadius: 2,
      },
    },
    MuiCssBaseline: {
      "@global": {
        body: {
          margin: 0,
          padding: 0,
        },
        button: {
          fontSize: "100%",
          fontFamily: "inherit",
          border: "0",
          padding: "0",
          margin: "0",
          cursor: "pointer",
          color: "inherit",
          backgroundColor: "unset",
          "& + button": {
            margin: 0,
          },
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
  },
});
